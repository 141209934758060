import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MySelectFilter from "../../components/MySelectFilter";
import SimpleDialog from "../../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../../components/common";
import FieldNameDialog from "../FieldNameDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Attachment Document" : "File đính kèm";
const fieldLabels_EN = {
  LoaiFile: "File type:",
  PhanLoai: "Category:",
  Attachs: "Attachs"
};
const fieldLabels_VN = {
  LoaiFile: "Loại file:",
  PhanLoai: "Phân loại:",
  Attachs: "Attachs"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnClose: "Close"
  }
  : {
    btnSave: "Lưu",
    btnClose: "Thoát"
  };
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, [
    "LoaiFile"
  ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

  if (atts.length == 0) {
    ret.Attach = true;
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class AttachmentDocumentEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: {
        DM_PhanLoai: [
          {
            TenPhanLoai: "So sánh chào giá",
            MaPhanLoai: 1
          },
          {
            TenPhanLoai: "Biên bản chọn nhà thầu",
            MaPhanLoai: 2
          },
          {
            TenPhanLoai: "Khác",
            MaPhanLoai: 0
          }
        ]
      },
      validateStatus: { error: false }, //trang thai validate
      after: {
        HangMucID: null,
        LoaiFile: null,
        PhanLoai: null,
        FileName: null,
        Content: null,
        ...(this.props.HangMucID ? { HangMucID: this.props.HangMucID } : {}),
      },
      Attachs: []
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() { }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveRecord = (index, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after.NoiDung[index] = value;
    s.dataChanged = true;
    this.setState(s);
  };

  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = () => {
    try {
      var check = validate_Data(this.state.after, this.state.Attachs);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          if (atts.length > 0) {
            this.state.after.FileName = atts[0].FileName;
            this.state.after.Content = atts[0].Content;
          } else {
            this.state.after.FileName = null;
            this.state.after.Content = null;
          }

          server
            .post("ChiPhi/DoRequest", {
              Function: "Proc_CP_HangMucSC_Att_Update",
              ThamSo: {
                ...this.state.after,
                PhanLoai: this.state.after.MaPhanLoai
              }
            })
            .then(() => {
              this.props.close(true);
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  getFileID() {
    try {
      let dinhKem = JSON.parse(this.state.after.DinhKem);
      return Array.isArray(dinhKem) && dinhKem.length > 0 ? dinhKem[0] : null;
    } catch (ex) {
      console.warn(ex);
    }

    return null;
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}{this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (<></>)}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel>
                  {fieldLabels.LoaiFile}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.LoaiFile}
                  onChange={(event) => {
                    this.saveField("LoaiFile", event.target.value);
                  }}
                  error={this.state.validateStatus.LoaiFile !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>
                  {fieldLabels.PhanLoai}
                </FormLabel>
              </Grid>
              <Grid item xs={10}>
                <MySelectFilter
                  fullWidth
                  options={this.state.DanhMuc.DM_PhanLoai}
                  optionLabel="TenPhanLoai"
                  optionValue="MaPhanLoai"
                  value={this.state.after}
                  onChange={item => {
                    this.saveData(item);
                  }} />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Attachs}</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.getAttachs() || this.state.after.FileName}
                  placeholder="Select an attachment"
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  error={this.state.validateStatus.Attach !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropzone
                  maxSize={2 * 1024 * 1024}
                  multiple={false}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Select file
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="AttachmentDocumentEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(AttachmentDocumentEditDialog);

import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  FormLabel,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import { formStyles, showError } from "../../components/common";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const fieldLabels_EN = {
  ShipCode: "M/V:",
  MaHangMuc: "Repair Items (RI):",
  NgayLap: "Date:",
  NguoiDeNghi: "Created by:",
  TruongPhong: "Approved by:",
  PhatSinh: "Extra Res:",
};
const fieldLabels_VN = {
  ShipCode: "Tàu:",
  MaHangMuc: "HMSC:",
  NgayLap: "Ngày lập:",
  NguoiDeNghi: "Người đề nghị:",
  TruongPhong: "Người duyệt:",
  PhatSinh: "Extra Res:",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class RepairSpecificationDetailTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "HMSC",
      after: {
        ShipID: null,
        ShipCode: null,
        MaHangMuc: null,
        NgayLap: null,
        TrangThai: 0,
        NoiDung: [],
        NguoiDeNghi: null,
        TruongPhong: null,
      },
      HangMucPhatSinh: [], //danh sach hang muc phat sinh
    };
  }

  componentDidMount() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: this.props.HangMucID,
        },
      })
      .then((response) => {
        let data = {
          after: {
            ...this.state.after,
            ...response.CP_HangMucSC[0],
            NoiDung: response.CP_HangMucSC_ND,
          },
        };
        this.setState(data);
      })
      .catch((error) => {
        showError(error);
      });
    this.loadPhatSinh();
  }
  loadPhatSinh = () => {
    //load danh sach hang muc phat sinh neu co
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_GetPhatSinh",
        ThamSo: {
          HangMucID: this.props.HangMucID,
        },
      })
      .then((response) => {
        let data = {
          HangMucPhatSinh: response.CP_HangMucSC,
        };
        this.setState(data);
      })
      .catch((error) => {
        showError(error);
      });
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  changeTab = (_event, tabName) => {
    this.setState({ selectedTab: tabName });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "ShipCode",
                })
              }
            >
              {fieldLabels.ShipCode}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            {this.state.after.ShipCode}
          </Grid>
          <Grid item xs={3}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "MaHangMuc",
                })
              }
            >
              {fieldLabels.MaHangMuc}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            {this.state.after.MaHangMuc}
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "NgayLap",
                })
              }
            >
              {fieldLabels.NgayLap}
            </FormLabel>
          </Grid>
          <Grid item xs={8}>
            {this.state.after.NgayLap
              ? moment(this.state.after.NgayLap).format("DD/MM/YYYY")
              : ""}
          </Grid>
          <Grid item xs={2} sx={{display: "flex", alignItems: "center"}}>
            <FormLabel>{fieldLabels.PhatSinh}</FormLabel>
          </Grid>
          <Grid item xs={22}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ padding: 1 }}
            >
              {this.state.HangMucPhatSinh.map((hangmuc, index) => (
                <React.Fragment key={"k" + index}>
                  <Link
                    href="#"
                    onClick={() =>
                      this.props
                        ? this.props.onResAction("sua", hangmuc, () => {
                          this.loadPhatSinh();
                        })
                        : null
                    }
                  >
                    {hangmuc.MaHangMuc}
                  </Link>
                  <Link
                    href="#"
                    sx={{ color: "red" }}
                    onClick={() =>
                      this.props.onResAction
                        ? this.props.onResAction("xoa", hangmuc, () => {
                          this.loadPhatSinh();
                        })
                        : null
                    }
                  >
                    [X]
                  </Link>
                </React.Fragment>
              ))}
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  this.props.onResAction
                    ? this.props.onResAction("them", null, () => {
                      this.loadPhatSinh();
                    })
                    : null
                }
              >
                Add
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
          <Grid item xs={11}>
            <hr />
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.groupHeader} align="center">
              Jobs
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <hr />
          </Grid>
          {this.state.after.NoiDung.map((item, index) => (
            <>
              <Grid item xs={1}>
                <FormLabel>{index + 1}.</FormLabel>
              </Grid>
              <Grid item xs={23}>
                {item.Code + " - " + item.CodeName + " (" + item.NoiDung + ")"}
              </Grid>
            </>
          ))}
        </Grid>
        <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
          <Grid item xs={24}>
            <hr />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "NguoiDeNghi",
                })
              }
            >
              {fieldLabels.NguoiDeNghi}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            {this.state.after.NguoiDeNghi}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "TruongPhong",
                })
              }
            >
              {fieldLabels.TruongPhong}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            {this.state.after.TruongPhong}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(formStyles, { withTheme: true })(
  RepairSpecificationDetailTab
);

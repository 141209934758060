import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import { MultiFileAttachControl } from "../components/MultiFileAttachControl";
import MyDateEditor from "../components/MyDateEditor";
import MySelectFilter from "../components/MySelectFilter";
import { formStyles, handleServerError, showError } from "../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

const fieldLabels_EN = {
  SoQuyetToan: "No:",
  NgayQuyetToan: "Date:",
  NgaySC1: "Repair from:",
  NgaySC2: "Repair to:",
  SoHoSo: "File number:",
  DiaDiem: "Repair location:",
  BienBan: "Biên bản:",
  MaCongTy: "Contractor:",
  MaHangMuc: "Repair Items (RI):",
  Attach: "Attach:",
  OtherDocuments: "Other documents:",
  IsDocking: "Docking"
};
const fieldLabels_VN = {
  SoQuyetToan: "Số:",
  NgayQuyetToan: "Ngày:",
  NgaySC1: "Sửa chữa từ ngày:",
  NgaySC2: "đến ngày:",
  SoHoSo: "Số hồ sơ:",
  DiaDiem: "Địa điểm sửa chữa:",
  BienBan: "Biên bản:",
  MaCongTy: "Đơn vị sửa chữa:",
  MaHangMuc: "HMSC số:",
  Attach: "Ðính kèm:",
  OtherDocuments: "Tài liệu khác:",
  IsDocking: "Trên đà"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class ExtraBillEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_CongTy: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        ShipID: this.props.ShipData.ShipID,
        ShipCode: this.props.ShipData.ShipCode,
        HangMucID: 0, //khong o
        IsQuyetToan: true,
        SoQuyetToan: null,
        NgayQuyetToan: new Date(),
        NgaySC1: null,
        NgaySC2: null,
        SoHoSo: null,
        DiaDiem: null,
        BienBan: null,
        MaCongTy: null,
        FileName: null,
        Content: null,
        IsDocking: 0,
        LoaiHinhQT: 0,
        Attachs: []
      },
      Attachs: []
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_CongTy", () => {
      this.setState(
        {
          DanhMuc: {
            ...this.state.DanhMuc
          },
        });
      this.loadData();
    });
  }

  loadData() {
    //lay thong tin de edit nue co
    if (this.props.edittingRecord) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_QuyetToanSC_Get",
          ThamSo: {
            QuyetToanID: this.props.edittingRecord.QuyetToanID,
          },
        })
        .then((response) => {
          let data = response.CP_QuyetToanSC[0];
          let attachs = [];
          try {
            const parsedAttachs = JSON.parse(data.Attachs);
            if (Array.isArray(parsedAttachs)) {
              attachs = parsedAttachs.map(att => {
                return {
                  "FileID": att.FileID,
                  "FileName": att.FileName
                }
              })
            }
          } catch (ex) {
            console.warn(ex);
          }

          this.setState({
            saving: false,
            after: {
              ...this.state.after,
              ...data,
              Attachs: attachs
            },
            Attachs: [
              {
                FileName: data.FileQuyetToan,
              },
            ]
          }, this.getNextSoHoSo);
        })
        .catch((error) => {
          showError(error);
        });
    } else {
      this.getNextSoHoSo();
    }
  }

  getNextSoHoSo() {
    if (!this.state.after.QuyetToanID) {
      server.post("ChiPhi/DoRequest", {
        Function: "Proc_CP_QuyetToanSC_NextSoHoSo"
      }).then(response => {
        this.saveField("SoHoSo", response?.CP_PhieuGV?.[0]?.SoHoSo);
      }).catch((error) => {
        showError(error);
      });
    }
  }

  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    s.saving = false;
    this.setState(s);
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.saving = false;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };

  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }

  validate_Data(data, atts) {
    //validate danh sach truong khong duoc trong'
    var ret = myLib.validateFields(
      data,
      [
        "SoQuyetToan",
        "NgayQuyetToan",
        "NgaySC1",
        "NgaySC2",
        "DiaDiem",
        "MaCongTy"
      ].reduce(
        (obj, item) => Object.assign(obj, { [item]: { allowEmpty: "false" } }),
        {}
      )
    );

    ret.error = Object.keys(ret).length > 0;

    if (this.state.Attachs == "") {
      ret.Attach = true;
    }

    return ret;
  }

  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after, this.state.Attachs);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          if (atts.length > 0) {
            this.state.after.FileName = atts[0].FileName;
            this.state.after.Content = atts[0].Content;
          } else {
            this.state.after.FileName = null;
            this.state.after.Content = null;
          }

          this.doReadAttachs(this.state.after.Attachs);
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  doReadAttachs = (attachs) => {
    var listProms = [];
    attachs.map((item) => {
      if (item.FileObject) {
        var prom = new Promise((resolve, reject) => {
          var fr = new FileReader();
          fr.onload = async (e) => {
            const text = e.target.result;
            const raw = Buffer.from(text).toString("base64");
            resolve({
              FileID: uuid(),
              FileName: item.FileObject.name,
              FileContent: raw,
            });
          };
          fr.onerror = reject;
          fr.readAsArrayBuffer(item.FileObject);
        });
        listProms.push(prom); //cho vao array
      } else {
        listProms.push(item); //giu lai thong tin FileID, FileName
      }
    });
    //chay tat ca promiese
    this.setState({ saving: true });
    Promise.all(listProms)
      .then((results) => {
        this.doSaveUploads(results);
      })
      .catch((error) => {
        showError(error);
        this.setState({ saving: false });
      });
  };

  //thuc hien cap nhat nhieu file dinh kem
  doSaveUploads = (uploads) => {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_QuyetToanSC_Upload",
        ThamSo: {
          ...this.state.after,
          Attachs: uploads,
        },
      })
      .then(() => {
        toast.success("Saved data successfully.");
        this.props.close(true);
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({ saving: false });
      });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              Extra Bill
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <></>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                Save
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={() => this.props.close()}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Stack spacing={20}>
              <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
                <Grid item xs={2}>
                  <FormLabel
                    onClick={() =>
                      this.setState({
                        show_thongtin_field: true,
                        Show_FieldName: "SoQuyetToan",
                      })
                    }
                  >
                    {fieldLabels.SoQuyetToan}
                  </FormLabel>
                </Grid>
                <Grid item xs={4}>
                  <OutlinedInput
                    fullWidth
                    autoFocus
                    value={this.state.after.SoQuyetToan}
                    onChange={(event) => {
                      this.saveField("SoQuyetToan", event.target.value);
                    }}
                    error={this.state.validateStatus.SoQuyetToan !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel
                    onClick={() =>
                      this.setState({
                        show_thongtin_field: true,
                        Show_FieldName: "NgayQuyetToan",
                      })
                    }
                  >
                    {fieldLabels.NgayQuyetToan}
                  </FormLabel>
                </Grid>
                <Grid item xs={4}>
                  <MyDateEditor
                    fullWidth
                    value={this.state.after.NgayQuyetToan}
                    onChange={(value) => {
                      this.saveField("NgayQuyetToan", value);
                    }}
                    error={
                      this.state.validateStatus.NgayQuyetToan !== undefined
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel
                    onClick={() =>
                      this.setState({
                        show_thongtin_field: true,
                        Show_FieldName: "NgaySC1",
                      })
                    }
                  >
                    {fieldLabels.NgaySC1}
                  </FormLabel>
                </Grid>
                <Grid item xs={4}>
                  <MyDateEditor
                    fullWidth
                    value={this.state.after.NgaySC1}
                    onChange={(value) => {
                      this.saveField("NgaySC1", value);
                    }}
                    error={this.state.validateStatus.NgaySC1 !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel
                    onClick={() =>
                      this.setState({
                        show_thongtin_field: true,
                        Show_FieldName: "NgaySC2",
                      })
                    }
                  >
                    {fieldLabels.NgaySC2}
                  </FormLabel>
                </Grid>
                <Grid item xs={4}>
                  <MyDateEditor
                    fullWidth
                    value={this.state.after.NgaySC2}
                    onChange={(value) => {
                      this.saveField("NgaySC2", value);
                    }}
                    error={this.state.validateStatus.NgaySC2 !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel>
                    {fieldLabels.SoHoSo}
                  </FormLabel>
                </Grid>
                <Grid item xs={4}>
                  <OutlinedInput
                    fullWidth
                    autoFocus
                    value={this.state.after.SoHoSo}
                    onChange={(event) => {
                      this.saveField("SoHoSo", event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel>{fieldLabels.IsDocking}</FormLabel>
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    id="IsDocking"
                    key="IsDocking"
                    sx={{ padding: 0 }}
                    checked={this.state.after.IsDocking == 1}
                    onChange={(event) => {
                      this.saveField("IsDocking", event.target.checked ? 1 : 0);
                    }}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={2}>
                  <FormLabel
                    onClick={() =>
                      this.setState({
                        show_thongtin_field: true,
                        Show_FieldName: "DiaDiem",
                      })
                    }
                  >
                    {fieldLabels.DiaDiem}
                  </FormLabel>
                </Grid>
                <Grid item xs={22}>
                  <OutlinedInput
                    fullWidth
                    value={this.state.after.DiaDiem}
                    onChange={(event) => {
                      this.saveField("DiaDiem", event.target.value);
                    }}
                    error={this.state.validateStatus.DiaDiem !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel
                    onClick={() =>
                      this.setState({
                        show_thongtin_field: true,
                        Show_FieldName: "BienBan",
                      })
                    }
                  >
                    {fieldLabels.BienBan}
                  </FormLabel>
                </Grid>
                <Grid item xs={22}>
                  <OutlinedInput
                    fullWidth
                    value={this.state.after.BienBan}
                    onChange={(event) => {
                      this.saveField("BienBan", event.target.value);
                    }}
                    error={this.state.validateStatus.BienBan !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel
                    onClick={() =>
                      this.setState({
                        show_thongtin_field: true,
                        Show_FieldName: "MaCongTy",
                      })
                    }
                  >
                    {fieldLabels.MaCongTy}
                  </FormLabel>
                </Grid>
                <Grid item xs={22}>
                  <MySelectFilter
                    fullWidth
                    options={this.state.DanhMuc.DM_CongTy}
                    optionValue="MaCongTy"
                    optionLabel="TenCongTy"
                    placeholder="Select contractor"
                    value={this.state.after}
                    onChange={(item) => {
                      this.saveData(item);
                    }}
                    error={this.state.validateStatus.MaCongTy !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormLabel>{fieldLabels.Attach}</FormLabel>
                </Grid>
                <Grid item xs={20}>
                  <OutlinedInput
                    fullWidth
                    value={this.getAttachs() || this.state.after.FileName}
                    readOnly
                    placeholder="Final bill"
                    onChange={() => {
                      this.state.Attachs = [];
                      this.setState({ dataChanged: true, saving: false });
                    }}
                    error={this.state.validateStatus.Attach !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Dropzone
                    maxSize={2 * 1024 * 1024}
                    multiple={false}
                    accept={{
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        [],
                    }}
                    validator={(file) => {
                      if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                        return null;
                      }
                      alert("File too large");
                      return true;
                    }}
                    onDrop={(acceptedFiles) => {
                      if (acceptedFiles.length > 0) {
                        this.state.Attachs = [];
                        acceptedFiles.map((file) => {
                          this.state.Attachs.push({
                            FileName: file.name,
                            File: file,
                          });
                        });
                        this.setState({ dataChanged: true, saving: false });
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Button fullWidth variant="contained">
                            Select file
                          </Button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Grid>
                <Grid item xs={2}>
                  <FormLabel>{fieldLabels.OtherDocuments}</FormLabel>
                </Grid>
                <Grid item xs={22}>
                  <MultiFileAttachControl
                    attachs={this.state.after.Attachs}
                    accept={{}}
                    onChange={(files, index) => {
                      console.log(files);
                      if (files) {
                        let list = Array.isArray(this.state.after.Attachs) ? this.state.after.Attachs : [];
                        list = list.concat(
                          files.map((item) => ({
                            FileName: item.name,
                            FileObject: item,
                          }))
                        );
                        this.saveField("Attachs", list);
                      } else { // xóa file tại vị trí index
                        let list = Array.isArray(this.state.after.Attachs) ? this.state.after.Attachs : [];

                        if (list.length > index) {
                          if (list[index].FileID) {
                            list[index].FileName = null;
                          } else {
                            list.splice(index, 1);
                          }
                          this.saveField("Attachs", list);
                        }
                      }
                    }}
                    deleteFileAction={(fileID) => {
                      return Promise.resolve(); // không cần gọi lên server
                    }}
                    error={this.state.validateStatus.Attach !== undefined}
                  />
                </Grid>
              </Grid>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(formStyles, { withTheme: true })(ExtraBillEditPage);

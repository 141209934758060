import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  OutlinedInput,
  Stack
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../../components/MyDateEditor";
import MySelectFilter from "../../components/MySelectFilter";
import SimpleDialog from "../../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnDelete: "Delete",
    btnDownload: "Download"
  }
  : {
    btnSave: "Lưu",
    btnDelete: "Xóa",
    btnDownload: "Tải về"
  };

const fieldLabels_EN = {
  SoDuToan: "No:",
  NgayDuToan: "Date:",
  NgaySC1: "Repair from:",
  NgaySC2: "Repair to:",
  DiaDiem: "Repair location:",
  MaCongTy: "Contractor:",
  MaHangMuc: "Repair Items (RI):",
  Attach: "Attach:",
  NgayDuKien: "Estimate Complete Final Bill:",
  ThongKe: "Use for Statistic"
};
const fieldLabels_VN = {
  SoDuToan: "Số:",
  NgayDuToan: "Ngày:",
  NgaySC1: "Sửa chữa từ ngày:",
  NgaySC2: "đến ngày:",
  DiaDiem: "Địa điểm sửa chữa:",
  MaCongTy: "Đơn vị sửa chữa:",
  MaHangMuc: "HMSC số:",
  Attach: "Ðính kèm:",
  NgayDuKien: "Ngày dự kiến hoàn thành quyết toán:",
  ThongKe: "Dùng cho thống kê"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class CostEstimationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_CongTy: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        HangMucID: this.props.HangMucID,
        SoDuToan: null,
        NgayDuToan: new Date(),
        NgaySC1: null,
        NgaySC2: null,
        DiaDiem: null,
        MaCongTy: null,
        FileName: null,
        Content: null,
        NgayDuKien: null,
        ThongKe: false,
        LoaiHinhQT: 0
      },
      Attachs: []
    };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_CongTy", () => { this.loadData(); });
  }

  loadData() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: this.props.HangMucID,
        },
      }).then((response) => {
        let s = this.state;
        s = {
          ...s,
          HangMucSC: Array.isArray(response.CP_HangMucSC) && response.CP_HangMucSC.length > 0 ? response.CP_HangMucSC[0] : {},
          PhieuGV: Array.isArray(response.CP_PhieuGV) && response.CP_PhieuGV.length > 0 ? response.CP_PhieuGV[0] : {},
          DuToanSC: Array.isArray(response.CP_DuToanSC) && response.CP_DuToanSC.length > 0 ? response.CP_DuToanSC[0] : null
        };

        s.after = {
          ...s.after,
          SoDuToan: s.DuToanSC ? s.DuToanSC.SoDuToan : "",
          NgayDuToan: s.DuToanSC ? s.DuToanSC.NgayDuToan : "",
          NgaySC1: s.DuToanSC ? s.DuToanSC.NgaySC1 : "",
          NgaySC2: s.DuToanSC ? s.DuToanSC.NgaySC2 : "",
          DiaDiem: s.DuToanSC ? s.DuToanSC.DiaDiem : "",
          NgayDuKien: s.DuToanSC ? s.DuToanSC.NgayDuKien : "",
          ThongKe: s.DuToanSC ? s.DuToanSC.ThongKe : "",
          MaCongTy: s.PhieuGV.MaCongTy,
          TenCongTy: s.PhieuGV.TenCongTy,
          FileName: s.DuToanSC ? s.DuToanSC.FileName : "",
          FileID: s.DuToanSC ? s.DuToanSC.FileID : null
        };

        this.setState(s);
      }).catch((error) => {
        showError(error);
      });
  }

  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    s.saving = false;
    this.setState(s);
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.saving = false;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };

  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }

  validate_Data(data, atts) {
    //validate danh sach truong khong duoc trong'
    var ret = myLib.validateFields(data, [
      "SoDuToan",
      "NgayDuToan",
      "NgaySC1",
      "NgaySC2",
      "DiaDiem",
      "MaCongTy"
    ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

    ret.error = Object.keys(ret).length > 0;

    if (this.state.Attachs == "") {
      ret.Attach = true;
    }

    return ret;
  }

  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after, this.state.Attachs);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          if (atts.length > 0) {
            this.state.after.FileName = atts[0].FileName;
            this.state.after.Content = atts[0].Content;
          } else {
            this.state.after.FileName = null;
            this.state.after.Content = null;
          }

          server
            .post("ChiPhi/DoRequest", {
              Function: atts.length > 0 ? "Proc_CP_DuToanSC_Upload" : "Proc_CP_DuToanSC_Update",
              ThamSo: {
                ...this.state.after
              },
            })
            .then(() => {
              this.loadData();
              toast.success("Saved data successfully.")
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  render() {
    return <>
      <Stack spacing={20}>
        <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "SoDuToan",
                })
              }
            >
              {fieldLabels.SoDuToan}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              fullWidth
              value={this.state.after.SoDuToan}
              onChange={(event) => {
                this.saveField("SoDuToan", event.target.value);
              }}
              error={this.state.validateStatus.SoDuToan !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "NgayDuToan",
                })
              }
            >
              {fieldLabels.NgayDuToan}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.NgayDuToan}
              onChange={(value) => {
                this.saveField("NgayDuToan", value);
              }}
              error={this.state.validateStatus.NgayDuToan !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "NgaySC1",
                })
              }
            >
              {fieldLabels.NgaySC1}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.NgaySC1}
              onChange={(value) => {
                this.saveField("NgaySC1", value);
              }}
              error={this.state.validateStatus.NgaySC1 !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "NgaySC2",
                })
              }
            >
              {fieldLabels.NgaySC2}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.NgaySC2}
              onChange={(value) => {
                this.saveField("NgaySC2", value);
              }}
              error={this.state.validateStatus.NgaySC2 !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "DiaDiem",
                })
              }
            >
              {fieldLabels.DiaDiem}
            </FormLabel>
          </Grid>
          <Grid item xs={22}>
            <OutlinedInput
              fullWidth
              value={this.state.after.DiaDiem}
              onChange={(event) => {
                this.saveField("DiaDiem", event.target.value);
              }}
              error={this.state.validateStatus.DiaDiem !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "MaCongTy",
                })
              }
            >
              {fieldLabels.MaCongTy}
            </FormLabel>
          </Grid>
          <Grid item xs={22}>
            <MySelectFilter
              fullWidth
              readOnly
              options={this.state.DanhMuc.DM_CongTy}
              optionValue="MaCongTy"
              optionLabel="TenCongTy"
              placeholder="Select contractor"
              value={this.state.after}
              onChange={(item) => {
                this.saveData(item);
              }}
              error={this.state.validateStatus.MaCongTy !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "MaHangMuc",
                })
              }
            >
              {fieldLabels.MaHangMuc}
            </FormLabel>
          </Grid>
          <Grid item xs={22}>
            <OutlinedInput
              fullWidth
              readOnly
              value={this.state.HangMucSC?.MaHangMuc}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>{fieldLabels.Attach}</FormLabel>
          </Grid>
          <Grid item xs={10}>
            <OutlinedInput
              fullWidth
              value={this.getAttachs() || this.state.after.FileName}
              readOnly
              placeholder="Budget Plan"
              onChange={() => {
                this.state.Attachs = [];
                this.setState({ dataChanged: true, saving: false });
              }}
              error={this.state.validateStatus.Attach !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <Dropzone
              maxSize={2 * 1024 * 1024}
              multiple={false}
              accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] }}
              validator={(file) => {
                if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                  return null;
                }
                alert("File too large");
                return true;
              }}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length > 0) {
                  this.state.Attachs = [];
                  acceptedFiles.map((file) => {
                    this.state.Attachs.push({
                      FileName: file.name,
                      File: file,
                    });
                  });
                  this.setState({ dataChanged: true, saving: false });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button fullWidth variant="contained">
                      Upload
                    </Button>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              disabled={!this.state.after.FileID}
              href={server.getRequest(`ChiPhi/GetFile?fileID=${this.state.after.FileID}`)}
              target="_blank"
            >
              {buttonLabels.btnDownload}
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={3}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "NgayDuKien",
                })
              }
            >
              {fieldLabels.NgayDuKien}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.NgayDuKien}
              onChange={(value) => {
                this.saveField("NgayDuKien", value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel control={
              <Checkbox
                size="medium"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                checked={this.state.after.ThongKe}
                onChange={(event) =>
                  this.saveField("ThongKe", event.target.checked)
                }
              />
            } label={fieldLabels.ThongKe} />
          </Grid>
        </Grid>
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "10px" }}>
          <Button
            color="success"
            variant="contained"
            disabled={!this.state.dataChanged || this.state.saving}
            onClick={() => this.doSave()}
          >
            {buttonLabels.btnSave}
          </Button>
          {this.state.DuToanSC && <Link
            href="#"
            sx={{ color: "red", zIndex: 0 }}
            onClick={() => {
              this.setState({ showConfirmXoa: true });
            }}
          >
            {buttonLabels.btnDelete}
          </Link>}
        </Stack >
      </Stack>

      {this.state.showConfirmXoa ? (
        <SimpleDialog
          confirm
          message={
            "Bạn muốn xóa dự toán: " + this.state.DuToanSC.SoDuToan + " ?"
          }
          open={true}
          OKRunning={this.state.saving}
          close={(ok) => {
            if (ok) {
              //thuc hien xoa
              this.setState({ saving: true });
              server
                .post("ChiPhi/DoRequest", {
                  Function: "Proc_CP_DuToanSC_Delete",
                  ThamSo: {
                    HangMucID: this.state.DuToanSC.HangMucID,
                  },
                })
                .then(() => {
                  this.setState({ showConfirmXoa: false, saving: false });
                  //reload
                  this.loadData();
                })
                .catch((error) => {
                  this.setState({ saving: false });
                  handleServerError(error);
                });
            } else {
              this.setState({ showConfirmXoa: false });
            }
          }}
        />
      ) : null}
    </>
  }
}


export default withStyles(formStyles, { withTheme: true })(CostEstimationTab);
import {
  FormLabel,
  Grid,
  OutlinedInput,
  Paper
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  loadDataError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import NumericFormat from "react-number-format";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import ButtonTimKiem from "../components/ButtonTimKiem";
import MySelectFilter from "../components/MySelectFilter";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    SoPhieuGV: "PO No:",
    SoQuyetToan: "Extra bill No:",
    SoAll: "PO/Extra bill No:",
    TenCongTy: "Company:"
  }
  : {
    SoPhieuGV: "PO số:",
    SoQuyetToan: "QT số:",
    SoAll: "PO/QT số:",
    TenCongTy: "Công ty:"
  };
const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search"
  }
  : {
    btnTimKiem: "Tìm kiếm"
  };
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "QuyetToanID", dir: 0, type: "number" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class InvoicePage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = [
      {
        name: "QuyetToanID",
        type: "string",
        defaultWidth: 70,
        headerAlign: "center",
        header: "ID"
      },
      {
        name: "SoPhieuGV",
        type: "string",
        defaultWidth: 150,
        headerAlign: "center",
        header: englishUI ? "PO No." : "Số PGV"
      },
      {
        name: "SoQuyetToan",
        type: "string",
        defaultWidth: 150,
        headerAlign: "center",
        header: englishUI ? "Bill No." : "Số QT"
      },
      {
        name: "TenCongTy",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Contractor" : "Đơn vị sửa chữa"
      },
      {
        name: "HoaDon_So",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Invoice No." : "Số hóa đơn",
      },
      {
        name: "HoaDon_Ngay",
        type: "string",
        defaultWidth: 120,
        headerAlign: "center",
        header: englishUI ? "Invoice date" : "Ngày xuất hóa đơn",
        render: ({ data }) => data.HoaDon_Ngay ? moment(data.HoaDon_Ngay).format("DD/MM/YYYY") : null
      },
      {
        name: "HoaDon_TruocThue",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Invoice value before tax" : "Giá trị hóa đơn trước thuế",
        render: ({ data }) => <NumericFormat
          value={data.HoaDon_TruocThue ?? ""}
          displayType="text"
          thousandSeparator=","
          style={data.HoaDon_TruocThue && data.TruocThue && data.HoaDon_TruocThue != data.TruocThue ? { color: "red" } : {}} />
      },
      {
        name: "HoaDon_SauThue",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Invoice value after tax" : "Giá trị hóa đơn sau thuế",
        render: ({ data }) => <NumericFormat
          value={data.HoaDon_SauThue ?? ""}
          displayType="text"
          thousandSeparator=","
          style={data.HoaDon_SauThue && data.SauThue && data.HoaDon_SauThue != data.SauThue ? { color: "red" } : {}} />
      },
      {
        name: "HoaDon_HanTT",
        type: "string",
        defaultWidth: 150,
        headerAlign: "center",
        header: englishUI ? "Payment due date" : "Thời hạn trả tiền",
        render: ({ data }) => data.HoaDon_HanTT ? moment(data.HoaDon_HanTT).format("DD/MM/YYYY") : null
      },
      {
        name: "Paid",
        type: "string",
        defaultWidth: 70,
        headerAlign: "center",
        header: englishUI ? "Paid" : "Đã thanh toán",
        render: ({ data }) => data.HoaDon_DaTT ? <CheckBox sx={{ fontSize: "14px" }} /> : <CheckBoxOutlineBlank sx={{ fontSize: "14px" }} />
      }
    ];
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_InvoiceData: [{ value: null, label: "All" }, { value: "PO", label: "PO" }, { value: "Extra", label: "Extra" }]
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            PageIndex: 1,
            PageSize: 25,
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
            //danh sach cac tieu chi tim kiem
            SoPhieuGV: null,
            SoQuyetToan: null,
            TenCongTy: null,
            InvoiceData: null
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value, callback) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state, callback);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    let new_columns_grid = [...this.columns_grid];

    switch (this.state.after.InvoiceData) {
      case "PO":
        new_columns_grid[1].visible = true;
        new_columns_grid[2].visible = false;
        break;
      case "Extra":
        new_columns_grid[1].visible = false;
        new_columns_grid[2].visible = true;
        break;
      default:
        new_columns_grid[1].visible = true;
        new_columns_grid[2].visible = true;
        break;
    }

    this.columns_grid = [...new_columns_grid];

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_QuyetToanSC_SearchInvoice",
          ThamSo: {
            ...this.state.after,
            ShipID: this.props.ShipData.ShipID,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: ""
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <MySelectFilter
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_InvoiceData}
                optionLabel="label"
                optionValue="value"
                value={this.state.DanhMuc.DM_InvoiceData.find(c => c.value == this.state.after.InvoiceData)}
                onChange={(item) => {
                  this.saveField("InvoiceData", item.value, () => {
                    switch (item.value) {
                      case "PO":
                        this.saveField("SoQuyetToan", null);
                        break;
                      case "Extra":
                        this.saveField("SoPhieuGV", null);
                        break;
                      default:
                        this.saveField("SoQuyetToan", null, () => {
                          this.saveField("SoPhieuGV", null);
                        });
                        break;
                    }
                  });
                }}
              ></MySelectFilter>
            </Grid>
            {this.state.after.InvoiceData === "PO" && <>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SoPhieuGV}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SoPhieuGV}
                  onChange={(e) => {
                    this.saveField("SoPhieuGV", e.target.value);
                  }}
                />
              </Grid>
            </>}
            {this.state.after.InvoiceData === "Extra" && <>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SoQuyetToan}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SoQuyetToan}
                  onChange={(e) => {
                    this.saveField("SoQuyetToan", e.target.value);
                  }}
                />
              </Grid>
            </>}
            {this.state.after.InvoiceData === "All" && <>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SoAll}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <OutlinedInput
                  fullWidth
                  onChange={(e) => {
                    this.saveField("SoPhieuGV", e.target.value, () => {
                      this.saveField("SoQuyetToan", e.target.value)
                    });
                  }}
                />
              </Grid>
            </>}
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.TenCongTy}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <OutlinedInput
                fullWidth
                value={this.state.after.TenCongTy}
                onChange={(e) => {
                  this.saveField("TenCongTy", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              rowStyle={({ data }) => {
                return data.HoaDon_CanhBaoTT ? {
                  backgroundColor: '#ef9a9a'
                } : {};
              }}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="QuyetToanID"
              rowHeight={null}
              minRowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
            ></ReactDataGrid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(InvoicePage);

import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  FormLabel,
  Grid,
  Link,
  OutlinedInput,
  Stack
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../../components/MyDateEditor";
import SimpleDialog from "../../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
const buttonLabels = englishUI
  ? {
    btnSave: "Save final bill",
    btnDelete: "Delete",
    btnDownload: "Download"
  }
  : {
    btnSave: "Lưu QT",
    btnDelete: "Xóa",
    btnDownload: "Tải về"
  };

const fieldLabels_EN = {
  SoQuyetToan: "No:",
  NgayQuyetToan: "Date:",
  NgaySC1: "Repair from:",
  NgaySC2: "Repair to:",
  SoHoSo: "File number:",
  DiaDiem: "Repair location:",
  MaCongTy: "Contractor:",
  MaHangMuc: "Repair Items (RI):",
  Attach: "Attach:"
};
const fieldLabels_VN = {
  SoQuyetToan: "Số:",
  NgayQuyetToan: "Ngày:",
  NgaySC1: "Sửa chữa từ ngày:",
  NgaySC2: "đến ngày:",
  SoHoSo: "Số hồ sơ:",
  DiaDiem: "Địa điểm sửa chữa:",
  MaCongTy: "Đơn vị sửa chữa:",
  MaHangMuc: "HMSC số:",
  Attach: "Ðính kèm:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class FinalSettlementTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      validateStatus: { error: false }, //trang thai validate
      after: {
        ShipID: this.props.ShipData?.ShipID,
        ShipCode: this.props.ShipData?.ShipCode,
        QuyetToanID: null,
        HangMucID: this.props.HangMucID,
        IsQuyetToan: true,
        SoQuyetToan: null,
        NgayQuyetToan: new Date(),
        NgaySC1: null,
        NgaySC2: null,
        SoHoSo: null,
        DiaDiem: null,
        MaCongTy: null
      },
      Attachs: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    server.post("ChiPhi/DoRequest", {
      Function: "Proc_CP_HangMucSC_Get",
      ThamSo: {
        HangMucID: this.props.HangMucID,
      },
    }).then((response) => {
      let s = this.state;
      s = {
        ...s,
        HangMucSC: Array.isArray(response.CP_HangMucSC) && response.CP_HangMucSC.length > 0 ? response.CP_HangMucSC[0] : {},
        PhieuGV: Array.isArray(response.CP_PhieuGV) && response.CP_PhieuGV.length > 0 ? response.CP_PhieuGV[0] : {},
        QuyetToanSC: Array.isArray(response.CP_QuyetToanSC) && response.CP_QuyetToanSC.filter(qt => qt.IsQuyetToan).length > 0 ? response.CP_QuyetToanSC.filter(qt => qt.IsQuyetToan)[0] : null,
        QuyetToanUoc: Array.isArray(response.CP_QuyetToanSC) && response.CP_QuyetToanSC.filter(qt => !qt.IsQuyetToan).length > 0 ? response.CP_QuyetToanSC.filter(qt => !qt.IsQuyetToan)[0] : null
      };

      s.after = {
        ...s.after,
        QuyetToanID: s.QuyetToanSC ? s.QuyetToanSC.QuyetToanID : "",
        SoQuyetToan: s.QuyetToanSC ? s.QuyetToanSC.SoQuyetToan : "",
        NgayQuyetToan: s.QuyetToanSC ? s.QuyetToanSC.NgayQuyetToan : s.after.NgayQuyetToan,
        NgaySC1: s.QuyetToanSC ? s.QuyetToanSC.NgaySC1 : "",
        NgaySC2: s.QuyetToanSC ? s.QuyetToanSC.NgaySC2 : "",
        SoHoSo: s.QuyetToanSC ? s.QuyetToanSC.SoHoSo : "",
        DiaDiem: s.QuyetToanSC ? s.QuyetToanSC.DiaDiem : "",
        MaCongTy: s.PhieuGV.MaCongTy,
        TenCongTy: s.PhieuGV.TenCongTy,
        FileName: s.QuyetToanSC ? s.QuyetToanSC.FileName : ""
      };

      this.setState(s, () => {
        if (!s.after.QuyetToanID) {
          server.post("ChiPhi/DoRequest", {
            Function: "Proc_CP_QuyetToanSC_NextSoHoSo"
          }).then(response => {
            this.saveField("SoHoSo", response?.CP_PhieuGV?.[0]?.SoHoSo);
          }).catch((error) => {
            showError(error);
          });
        }
      });
    }).catch((error) => {
      showError(error);
    });
  }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    s.saving = false;
    this.setState(s);
  };

  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }

  validate_Data(data) {
    //validate danh sach truong khong duoc trong'
    var ret = myLib.validateFields(data, [
      "NgayQuyetToan"
    ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

    ret.error = Object.keys(ret).length > 0;
    return ret;
  }

  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          server
            .post("ChiPhi/DoRequest", {
              Function: "Proc_CP_QuyetToanSC_Upload",
              ThamSo: {
                ...this.state.after,
                FileName: atts.length > 0 ? atts[0].FileName : null,
                Content: atts.length > 0 ? atts[0].Content : null
              }
            })
            .then(() => {
              toast.success("Saved data successfully.");
              this.setState({ saving: false });
              this.loadData();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  render() {
    return <>
      <>
        <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.SoQuyetToan}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              fullWidth
              value={this.state.after.SoQuyetToan}
              onChange={(event) => {
                this.saveField("SoQuyetToan", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.NgayQuyetToan}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.NgayQuyetToan}
              onChange={(value) => {
                this.saveField("NgayQuyetToan", value);
              }}
              error={this.state.validateStatus.NgayQuyetToan !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.NgaySC1}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.NgaySC1}
              onChange={(value) => {
                this.saveField("NgaySC1", value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.NgaySC2}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.NgaySC2}
              onChange={(value) => {
                this.saveField("NgaySC2", value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.SoHoSo}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              fullWidth
              value={this.state.after.SoHoSo}
              onChange={(event) => {
                this.saveField("SoHoSo", event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.DiaDiem}
            </FormLabel>
          </Grid>
          <Grid item xs={22}>
            <OutlinedInput
              fullWidth
              value={this.state.after.DiaDiem}
              onChange={(event) => {
                this.saveField("DiaDiem", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.MaCongTy}
            </FormLabel>
          </Grid>
          <Grid item xs={22}>
            <OutlinedInput
              fullWidth
              readOnly
              value={this.state.after.TenCongTy}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>
              {fieldLabels.MaHangMuc}
            </FormLabel>
          </Grid>
          <Grid item xs={22}>
            <OutlinedInput
              fullWidth
              readOnly
              value={this.state.HangMucSC?.MaHangMuc}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel>{fieldLabels.Attach}</FormLabel>
          </Grid>
          <Grid item xs={10}>
            <OutlinedInput
              fullWidth
              value={this.getAttachs() || this.state.after.FileName}
              readOnly
              placeholder="Final bill"
              onChange={() => {
                this.state.Attachs = [];
                this.setState({ dataChanged: true, saving: false });
              }}
              error={this.state.validateStatus.Attach !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <Dropzone
              maxSize={2 * 1024 * 1024}
              multiple={false}
              accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] }}
              validator={(file) => {
                if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                  return null;
                }
                alert("File too large");
                return true;
              }}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length > 0) {
                  this.setState({
                    Attachs: acceptedFiles.map(file => {
                      return {
                        FileName: file.name,
                        File: file
                      }
                    }),
                    dataChanged: true,
                    saving: false
                  });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button fullWidth variant="contained">Select file</Button>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={10}>
            <Stack alignItems="center" height="100%" direction="row" spacing={2}>
              {this.state.QuyetToanSC?.FileID && <Link href={server.getRequest(`ChiPhi/GetFile?fileID=${this.state.QuyetToanSC?.FileID}`)}>
                {buttonLabels.btnDownload}
              </Link>}
            </Stack>
          </Grid>
        </Grid>
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "10px" }}>
          <Button
            color="success"
            variant="contained"
            disabled={!this.state.dataChanged || this.state.saving}
            onClick={() => this.doSave()}
          >
            {buttonLabels.btnSave}
          </Button>
          {this.state.after.QuyetToanID && <Link
            href="#"
            sx={{ color: "red", zIndex: 0 }}
            onClick={() => {
              this.setState({ showConfirmXoa: true });
            }}
          >
            {buttonLabels.btnDelete}
          </Link>}
        </Stack >
      </>

      {this.state.showConfirmXoa ? (
        <SimpleDialog
          confirm
          message={
            "Bạn muốn xóa quyết toán: " + this.state.after.SoQuyetToan + " ?"
          }
          open={true}
          OKRunning={this.state.saving}
          close={(ok) => {
            if (ok) {
              //thuc hien xoa
              this.setState({ saving: true });
              server
                .post("ChiPhi/DoRequest", {
                  Function: "Proc_CP_QuyetToanSC_Delete",
                  ThamSo: {
                    QuyetToanID: this.state.after.QuyetToanID,
                  },
                })
                .then(() => {
                  this.setState({ showConfirmXoa: false, saving: false });
                  //reload
                  this.loadData();
                })
                .catch((error) => {
                  this.setState({ saving: false });
                  handleServerError(error);
                });
            } else {
              this.setState({ showConfirmXoa: false });
            }
          }}
        />
      ) : null}
    </>
  }
}


export default withStyles(formStyles, { withTheme: true })(FinalSettlementTab);
import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import SelectFilter from "react-select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";
import MyDateEditor from "../components/MyDateEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import {
  filterStyles,
  formStyles,
  handleServerError,
  showError,
} from "../components/common";
import FieldNameDialog from "./FieldNameDialog";
var numeral = require("numeral");
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Repair Items (RI)" : "Yêu cầu sửa chữa";
const fieldLabels_EN = {
  ShipCode: "M/V:",
  MaHangMuc: "Repair Items (RI):",
  NgayLap: "Date:",
  TenHangMuc: "Subject:",
  NguoiDeNghi: "Created by:",
  TruongPhong: "Approved by:",
  IsNormal: "Normal:",
  IsDocking: "Docking:",
};
const fieldLabels_VN = {
  ShipCode: "Tàu:",
  MaHangMuc: "HMSC:",
  NgayLap: "Ngày lập:",
  TenHangMuc: "Yêu cầu:",
  NguoiDeNghi: "Người đề nghị:",
  TruongPhong: "Người duyệt:",
  IsNormal: "Đầu bến:",
  IsDocking: "Trên đà:",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
    btnAddAllJob: "Add all jobs",
    btnSave: "Save",
    btnClose: "Close",
    btnExport: "Export",
    IsNormal: "Normal:",
    IsDocking: "Docking:",
  }
  : {
    btnAddAllJob: "Thêm tất cả",
    btnSave: "Lưu",
    btnClose: "Thoát",
    btnExport: "Xuất file",
    IsNormal: "Đầu bến:",
    IsDocking: "Trên đà:",
  };
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, {
    MaHangMuc: {
      allowEmpty: "false",
    },
    NgayLap: {
      allowEmpty: "false",
    },
    NguoiDeNghi: {
      allowEmpty: "false",
    },
    TruongPhong: {
      allowEmpty: "false",
    },
  });

  ret.error = Object.keys(ret).length > 0;

  ret.NoiDung = data.NoiDung.map((job) => {
    return {
      MucLuc: myLib.isEmpty(job.MucLuc),
      NoiDung: myLib.isEmpty(job.NoiDung),
    };
  });

  ret.error = ret.error || ret.NoiDung.some((job) => job.MucLuc || job.NoiDung);

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class RepairSpecificationEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_Job: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        ShipID: null,
        ShipCode: null,
        MaHangMuc: null,
        NgayLap: new Date(),
        TenHangMuc: null,
        TrangThai: 0,
        IsDocking: false,
        NoiDung: [this.newRecord()],
        NguoiDeNghi: localStorage.getItem("RepairRequisition.NguoiDeNghi"),
        TruongPhong: localStorage.getItem("RepairRequisition.TruongPhong"),
        ...(props.edittingRecord ? props.edittingRecord : {}),
        ...(this.props.ShipData
          ? {
            ShipID: this.props.ShipData.ShipID,
            ShipCode: this.props.ShipData.ShipCode,
          }
          : {}),
        PhatSinh: props.HangMucSC_Goc ? 1 : 0, //co phai la hang muc phat sinh khong
      },
      showConfirmExit: false,
    };
    console.log(this.state.after, this.props.edittingRecord);
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadDanhSach();
    if (
      this.state.after.MaHangMuc == null &&
      this.props.HangMucSC_Goc == undefined
    ) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_HangMucSC_New",
          ThamSo: {
            ShipID: this.state.after.ShipID,
            ShipCode: this.state.after.ShipCode,
          },
        })
        .then((response) => {
          this.saveField("MaHangMuc", response.CP_HangMucSC[0].MaHangMuc);
        })
        .catch((error) => {
          showError(error);
        });
    }
    //neu la che do addnew
    if (this.props.HangMucSC_Goc && !this.props.edittingRecord) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_HangMucSC_GetPhatSinh",
          ThamSo: {
            HangMucID: this.props.HangMucSC_Goc.HangMucID,
          },
        })
        .then((response) => {
          this.state.after.MaHangMuc =
            this.props.HangMucSC_Goc.MaHangMuc +
            "." +
            numeral(response.CP_HangMucSC.length + 1).format("00");
          this.state.after.IsDocking = this.props.HangMucSC_Goc.IsDocking; //lay theo cai cha
          this.setState({
            saving: false,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  loadDanhSach() {
    //lay danh sach noi dung sua chua
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_YeuCauSC_ND_Pending",
        ThamSo: {
          ShipID: this.state.after.ShipID,
          ShipCode: this.state.after.ShipCode,
          IsDocking: this.state.after.IsDocking,
        },
      })
      .then((response) => {
        this.setState({
          DanhMuc: {
            ...this.state.DanhMuc,
            DM_Job: response.CP_YeuCauSC_ND,
          },
        });
      })
      .catch((error) => {
        showError(error);
      });
  }
  getMaYeuCau() {
    if (!this.props.HangMucSC_Goc) {
      //khong tu dong doi voi Res phat sinh

      let nam = new Date(this.state.after.NgayLap).getFullYear();

      if (this.props.edittingRecord != null) {
        // khi edit HMSC, chỉ đổi MaHangMuc nếu thay đổi năm của trường NgayLap
        if (this.props.edittingRecord.Nam == nam) {
          this.saveField("MaHangMuc", this.props.edittingRecord.MaHangMuc);
          return;
        }
      }

      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_HangMucSC_New",
          ThamSo: {
            ShipCode: this.state.after.ShipCode,
            IsDocking: this.state.after.IsDocking,
            Nam: nam
          }
        })
        .then((response) => {
          this.saveField("MaHangMuc", response.CP_HangMucSC[0].MaHangMuc);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  saveField = (fieldName, value, callback) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false }, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveRecord = (record, index, field, value, callback) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.NoiDung[index] = record;
    s.dataChanged = true;
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };

  newRecord(type = "combobox") {
    return {
      Code: null,
      MucLuc: null,
      NoiDung: null,
      TrangThaiXL: 0,
      DVT: null,
      KhoiLuong: null,
      Type: type,
    };
  }

  addRecord(type = "combobox") {
    var s = { after: { ...this.state.after } };
    s.after.NoiDung.push(this.newRecord(type));
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.NoiDung.splice(index, 1);
    s.after.NoiDung.forEach((job, index) => {
      return {
        ...job,
        STT: index + 1,
      };
    });
    s.dataChanged = true;

    this.setState(s);
  }

  doAddAllJob() {
    var s = { after: { ...this.state.after } };

    let totalItems = s.after.NoiDung.filter(item => item.MaNoiDung).length ?? 0;

    var missingJobs = this.state.DanhMuc.DM_Job.filter((job) => {
      return !s.after.NoiDung.some(item => item.MaNoiDung == job.MaNoiDung);
    }).map((item, index) => {
      return {
        ...item,
        MucLuc: totalItems + index + 1
      }
    });

    s.after.NoiDung = s.after.NoiDung.filter(item => item.MaNoiDung).concat(missingJobs);
    s.dataChanged = true;
    this.setState(s);
  }

  doSave = () => {
    try {
      if (this.state.after.YeuCauID == null) {
        localStorage.setItem(
          "RepairRequisition.NguoiDeNghi",
          this.state.after.NguoiDeNghi
        );
        localStorage.setItem(
          "RepairRequisition.TruongPhong",
          this.state.after.TruongPhong
        );
      }
      var check = validate_Data(this.state.after);
      console.log(check, this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_CP_HangMucSC_Update",
            ThamSo: {
              ...this.state.after,
              NoiDung: this.state.after.NoiDung.map((job, index) => {
                return {
                  Code: job.Code,
                  MucLuc: job.MucLuc,
                  NoiDung: job.NoiDung,
                  TrangThaiXL: job.TrangThaiXL,
                  YeuCauID: job.YeuCauID,
                  MaNoiDungYC: job.MaNoiDung,
                  DVT: job.DVT,
                  KhoiLuong:
                    typeof job.KhoiLuong == "string" &&
                      job.KhoiLuong.trim() == ""
                      ? null
                      : job.KhoiLuong,
                };
              }),
            },
          })
          .then(() => {
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
              },
              () => {
                this.props.close(true);
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <></>
              )}
              <Button
                color="primary"
                variant="contained"
                disabled={this.state.saving || this.state.DanhMuc.DM_Job.length == 0}
                onClick={() => this.doAddAllJob()}
              >
                {buttonLabels.btnAddAllJob}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ShipCode",
                    })
                  }
                >
                  {fieldLabels.ShipCode}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="ShipCode"
                  key="ShipCode"
                  fullWidth
                  readOnly
                  value={this.state.after.ShipCode}
                  onChange={(event) => {
                    this.saveField("ShipCode", event.target.value);
                  }}
                  error={this.state.validateStatus.ShipCode !== undefined}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaHangMuc",
                    })
                  }
                >
                  {fieldLabels.MaHangMuc}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="MaHangMuc"
                  key="MaHangMuc"
                  fullWidth
                  placeholder=""
                  value={this.state.after.MaHangMuc}
                  onChange={(event) => {
                    this.saveField("MaHangMuc", event.target.value);
                  }}
                  error={this.state.validateStatus.MaHangMuc !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NgayLap",
                    })
                  }
                >
                  {fieldLabels.NgayLap}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateEditor
                  id="NgayLap"
                  key="NgayLap"
                  fullWidth
                  value={this.state.after.NgayLap}
                  onChange={(value) => {
                    this.saveField("NgayLap", value, () => {
                      this.getMaYeuCau();
                    });
                  }}
                  error={this.state.validateStatus.NgayLap !== undefined}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.IsNormal}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  id="IsNormal"
                  key="IsNormal"
                  sx={{ padding: 0 }}
                  checked={!this.state.after.IsDocking}
                  onChange={(event) => {
                    if (
                      !this.props.HangMucSC_Goc &&
                      !(
                        this.props.edittingRecord &&
                        this.props.edittingRecord.PhatSinh
                      )
                    ) {
                      this.saveField("IsDocking", !event.target.checked, () => {
                        this.loadDanhSach();
                        this.getMaYeuCau();
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.IsDocking}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  id="IsDocking"
                  key="IsDocking"
                  sx={{ padding: 0 }}
                  checked={this.state.after.IsDocking}
                  onChange={(event) => {
                    if (
                      !this.props.HangMucSC_Goc &&
                      !(
                        this.props.edittingRecord &&
                        this.props.edittingRecord.PhatSinh
                      )
                    ) {
                      this.saveField("IsDocking", event.target.checked, () => {
                        this.loadDanhSach();
                        this.getMaYeuCau();
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>
                  {fieldLabels.TenHangMuc}
                </FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="TenHangMuc"
                  key="TenHangMuc"
                  fullWidth
                  placeholder=""
                  value={this.state.after.TenHangMuc}
                  onChange={(event) => {
                    this.saveField("TenHangMuc", event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  Jobs
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>
                  <b>TT</b>
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <FormLabel>
                  <b>No.</b>
                </FormLabel>
              </Grid>
              <Grid item xs={16}>
                <FormLabel>
                  <b>Job</b>
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>
                  <b>Unit</b>
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>
                  <b>Quantity</b>
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                {" "}
              </Grid>
              {this.state.after.NoiDung.map((item, index) => (
                <>
                  <Grid item xs={1}>
                    <FormLabel>{index + 1}.</FormLabel>
                  </Grid>
                  <Grid item xs={1}>
                    <MyNumberEditor
                      decimalScale={0}
                      inputProps={{ min: 1, max: 100 }}
                      value={item.MucLuc}
                      onChange={value => {
                        this.saveRecord(
                          item,
                          index,
                          "MucLuc",
                          value
                        );
                      }}
                      error={
                        Array.isArray(this.state.validateStatus.NoiDung) &&
                        this.state.validateStatus.NoiDung[index]?.MucLuc == true
                      }
                    />
                  </Grid>
                  <Grid item xs={16}>
                    {item.Type == "outlined-input" ||
                      (item.Type == null && item.Code == null) ? (
                      <OutlinedInput
                        fullWidth
                        placeholder="Enter job"
                        value={item.NoiDung}
                        onChange={(event) => {
                          this.saveRecord(
                            item,
                            index,
                            "NoiDung",
                            event.target.value
                          );
                        }}
                        error={
                          Array.isArray(this.state.validateStatus.NoiDung) &&
                          this.state.validateStatus.NoiDung[index]?.NoiDung ==
                          true
                        }
                      />
                    ) : (
                      <SelectFilter
                        fullWidth
                        options={this.state.DanhMuc.DM_Job}
                        getOptionLabel={(item) => {
                          if (!item.JobCode) {
                            var find = this.state.DanhMuc.DM_Job.find(
                              (rec) => rec.MaNoiDung == item.MaNoiDungYC
                            );
                            var jobCode = find ? find.JobCode : "";
                            item.JobCode = jobCode;
                          }
                          return item.Code
                            ? `${item.JobCode ? item.JobCode + ". " : ""}${item.NoiDung
                            }`
                            : "Select job";
                        }}
                        getOptionValue={(item) => item.MaNoiDung}
                        styles={filterStyles}
                        menuPosition={"fixed"}
                        value={item}
                        onChange={(selectedItem) => {
                          selectedItem.MucLuc = item.MucLuc;
                          selectedItem.DVT = selectedItem.DVT ?? "";
                          selectedItem.KhoiLuong = selectedItem.KhoiLuong ?? "";

                          let s = { after: { ...this.state.after } };
                          s.after.NoiDung[index] = selectedItem;
                          s.dataChanged = true;
                          this.setState(s);
                        }}
                        error={
                          Array.isArray(this.state.validateStatus.NoiDung) &&
                          this.state.validateStatus.NoiDung[index]?.MaNoiDung ==
                          true
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <OutlinedInput
                      fullWidth
                      value={item.DVT}
                      onChange={(event) => {
                        this.saveRecord(item, index, "DVT", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <OutlinedInput
                      fullWidth
                      value={item.KhoiLuong}
                      onChange={(event) => {
                        this.saveRecord(
                          item,
                          index,
                          "KhoiLuong",
                          event.target.value
                        );
                      }}
                    />
                  </Grid>
                  {index == 0 ? (
                    this.state.after.NoiDung.length > 1 ? (
                      <>
                        <Grid item xs={2}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              this.addRecord();
                            }}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              this.addRecord("outlined-input");
                            }}
                          >
                            <AddBoxIcon color="primary" size={24} />
                          </IconButton>
                          <IconButton
                            size="small"
                            fullWidth
                            onClick={() => {
                              this.removeRecord(index);
                            }}
                          >
                            <ClearIcon color="warning" size={24} />
                          </IconButton>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={2}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            this.addRecord();
                          }}
                          onContextMenu={() => {
                            this.addRecord("outlined-input");
                          }}
                        >
                          <AddBoxIcon color="primary" size={24} />
                        </IconButton>
                      </Grid>
                    )
                  ) : (
                    <>
                      <Grid item xs={2}>
                        <IconButton
                          size="small"
                          fullWidth
                          onClick={() => {
                            this.removeRecord(index);
                          }}
                        >
                          <ClearIcon color="warning" size={24} />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                </>
              ))}
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NguoiDeNghi",
                    })
                  }
                >
                  {fieldLabels.NguoiDeNghi}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="NguoiDeNghi"
                  key="NguoiDeNghi"
                  fullWidth
                  value={this.state.after.NguoiDeNghi}
                  onChange={(event) => {
                    this.saveField("NguoiDeNghi", event.target.value);
                  }}
                  error={this.state.validateStatus.NguoiDeNghi !== undefined}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "TruongPhong",
                    })
                  }
                >
                  {fieldLabels.TruongPhong}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="TruongPhong"
                  key="TruongPhong"
                  fullWidth
                  value={this.state.after.TruongPhong}
                  onChange={(event) => {
                    this.saveField("TruongPhong", event.target.value);
                  }}
                  error={this.state.validateStatus.TruongPhong !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="RepairSpecificationEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  RepairSpecificationEditDialog
);

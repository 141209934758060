import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import SimpleDialog from "../../components/SimpleDialog";
import RepairSpecificationDetailTab from "../PODetail/RepairSpecificationDetailTab";

import {
  formStyles,
  handleServerError,
  showError
} from "../../components/common";
import RepairSpecificationEditDialog from "../RepairSpecificationEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Repair Items (RI) detail" : "Chi tiết HMSC";
const buttonLabels = englishUI
  ? {
    btnClose: "Close",
  }
  : {
    btnClose: "Thoát",
  };

class RepairSpecificationDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "HMSC",
      show_edit_extra_Res: false,
      edittingExtraRes: null,
    };
  }

  componentDidMount() { }

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  changeTab = (_event, tabName) => {
    this.setState({ selectedTab: tabName });
  };
  edit = (hangmuc, callback) => {
    //show edit hang muc extra

    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: hangmuc.HangMucID,
        },
      })
      .then((response) => {
        let data = {
          edittingExtraRes: {
            ...response.CP_HangMucSC[0],
            NoiDung: response.CP_HangMucSC_ND.map((item) => {
              return {
                ...item,
                MaYeuCau: item.MaYeuCau ?? item.MaYeuCauSC,
                MaNoiDung: item.MaNoiDung ?? item.MaNoiDungYC,
              };
            }),
          },
          show_edit_extra_Res: true,
          edit_ResExtra_callback: callback,
        };
        this.setState(data);
      })
      .catch((error) => {
        showError(error);
      });
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <></>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent sx={{ maxHeight: "100vh" }}>
          <RepairSpecificationDetailTab
            HangMucID={this.props.HangMucID}
            parentHeight={375}
            HangMucSC={this.props.edittingRecord}
            onResAction={(action, hangmuc, callback) => {
              if (action == "them") {
                this.setState({
                  show_edit_extra_Res: true,
                  edittingExtraRes: null,
                  edit_ResExtra_callback: callback,
                });
              }
              if (action == "sua") {
                this.edit(hangmuc, callback);
              }
              if (action == "xoa") {
                this.setState({
                  showConfirmXoa: true,
                  edittingExtraRes: hangmuc,
                  edit_ResExtra_callback: callback,
                });
              }
            }}
          />
        </DialogContent>
        {this.state.show_edit_extra_Res ? (
          <RepairSpecificationEditDialog
            open={true}
            edittingRecord={this.state.edittingExtraRes}
            ShipData={this.props.ShipData}
            HangMucSC_Goc={this.props.edittingRecord}
            close={(saved) => {
              console.log(saved, this.state);
              if (saved) {
                if (this.state.edit_ResExtra_callback) {
                  //callback de hien thi lai
                  this.state.edit_ResExtra_callback();
                }
                this.setState({
                  show_edit_extra_Res: false,
                });
              } else {
                this.setState({
                  show_edit_extra_Res: false,
                });
              }
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa: " + this.state.edittingExtraRes.MaHangMuc + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_HangMucSC_Delete",
                      ThamSo: {
                        HangMucID: this.state.edittingExtraRes.HangMucID,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      if (this.state.edit_ResExtra_callback) {
                        //callback de hien thi lai
                        this.state.edit_ResExtra_callback();
                      }
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}

export default withStyles(formStyles, { withTheme: true })(
  RepairSpecificationDetailDialog
);

import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../components/MyDateEditor";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../components/common";
import FieldNameDialog from "./FieldNameDialog";
import RepairSpecificationEditDialog from "./RepairSpecificationEditDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Purchase order" : "Phiếu giao việc";
const fieldLabels_EN = {
  HangMucID: "Repair Item:",
  MaCongTy: "Contractor:",
  GiamDoc: "Director:",
  SoPhieuGV: "PO No:",
  NgayGV: "Date:",
  SoHoSo: "File number:",
  PhongYeuCau: "From department:",
  YeuCau: "Subject:",
  MoTa: "Description:",
  GhiChu: "Remark:",
  NguoiDeNghi: "Created by:",
  TruongPhong: "Approved by:",
  Attachs: "Attachs"
};
const fieldLabels_VN = {
  HangMucID: "Hạng mục sửa chữa:",
  MaCongTy: "Đơn vị sửa chữa:",
  GiamDoc: "Giám đốc:",
  SoPhieuGV: "Số PGV:",
  NgayGV: "Ngày GV:",
  SoHoSo: "Số hồ sơ:",
  PhongYeuCau: "Phòng yêu cầu",
  YeuCau: "Yêu cầu:",
  MoTa: "Mô tả:",
  GhiChu: "Ghi chú:",
  NguoiDeNghi: "Người đề nghị:",
  TruongPhong: "Người duyệt:",
  Attachs: "Đính kèm"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}

const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnClose: "Close",
    btnDownload: "Download",
    btnEdit: "Edit"
  }
  : {
    btnSave: "Lưu",
    btnClose: "Thoát",
    btnDownload: "Tải về",
    btnEdit: "Chỉnh sửa"
  };
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, [
    "HangMucID",
    "NgayGV",
    "PhongYeuCau",
    "YeuCau",
    "NguoiDeNghi",
    "TruongPhong"
  ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class POEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_CongTy: [], DM_HMSC: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        HangMucID: null,
        MaCongTy: null,
        GiamDoc: null,
        SoPhieuGV: null,
        NgayGV: new Date(),
        SoHoSo: null,
        PhongYeuCau: null,
        YeuCau: null,
        MoTa: `Tàu ………. dự kiến sẽ về …..từ ngày ….đến ngày ……
Đề nghị Quý Công ty bố trí sửa chữa theo hạng mục ……...đính kèm`,
        GhiChu: `+ Đơn vị sửa chữa lưu ý: 
- Có trách nhiệm thu xếp giấy phép phù hợp trước khi tiến hành công việc;
- Tuân thủ các qui định về an ninh, an toàn và phòng chống cháy nổ của tàu và chính quyền Cảng;
- Việc thi công phải được sự đồng ý của Thuyền trưởng; Máy trưởng và các Sỹ quan phụ trách.
- Mọi phát sinh so với Dự toán phải được sự nhất trí của VOSCO mới được tiến hành thi công.
- VOSCO chịu trách nhiệm cung cấp điện; cần cẩu phục vụ sửa chữa.
+ Thông tin liên hệ:
-	CVKT:……………..- Điện thoại
-	Thuyền Trưởng: …………..- Điện thoại`,
        LoaiHinhPO: 0,
        NguoiDeNghi: localStorage.getItem("RepairRequisition.NguoiDeNghi"),
        TruongPhong: localStorage.getItem("RepairRequisition.TruongPhong"),
        DinhKem: [],
        Attachs: [],
        ...(props.edittingRecord ? props.edittingRecord : {}),
        ...(this.props.ShipData?.ShipID ? { ShipID: this.props.ShipData.ShipID } : {}),
        ...(this.props.ShipData?.ShipCode ? { ShipCode: this.props.ShipData.ShipCode } : {})
      },
      Attachs: []
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_CongTy,DM_LoaiHinhPO", () => { });

    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_GetFree",
        ThamSo: {
          HangMucID: this.state.after.HangMucID,
          ShipID: this.state.after.ShipID
        },
      })
      .then((response) => {
        this.setState(
          {
            DanhMuc: {
              ...this.state.DanhMuc,
              DM_HMSC: response.CP_HangMucSC.map(item => {
                return {
                  HangMucID: item.HangMucID,
                  MaHangMuc: item.MaHangMuc
                }
              }),
            },
          }
        );
      })
      .catch((error) => {
        showError(error);
      });

    if (!this.state.after.HangMucID) {
      server.post("ChiPhi/DoRequest", {
        Function: "Proc_CP_PhieuGV_NextSoHoSo"
      }).then(response => {
        this.saveField("SoHoSo", response?.CP_PhieuGV?.[0]?.SoHoSo);
      }).catch((error) => {
        showError(error);
      });
    }
  }

  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }

  getPGVNextSTT() {
    if (this.state.after.HangMucID) {
      let nam = new Date(this.state.after.NgayGV).getFullYear();

      if (this.props.edittingRecord != null) {
        // khi edit PO, chỉ đổi SoPhieuGV nếu thay đổi năm của trường NgayGV
        if (this.props.edittingRecord.Nam == nam) {
          this.saveField("SoPhieuGV", this.props.edittingRecord.SoPhieuGV);
          return;
        }
      }

      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_PhieuGV_NextSTT",
          ThamSo: {
            ShipID: this.state.after.ShipID,
            ShipCode: this.state.after.ShipCode,
            Nam: new Date(this.state.after.NgayGV).getFullYear(),
            HangMucID: this.state.after.HangMucID
          },
        })
        .then(response => {
          this.saveField("SoPhieuGV", response.CP_PhieuGV[0].Request);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  saveField = (fieldName, value, callback) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false }, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveRecord = (index, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after.NoiDung[index] = value;
    s.dataChanged = true;
    this.setState(s);
  };

  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = () => {
    try {
      var check = validate_Data(this.state.after, this.state.Attachs);
      if (!check.error) {
        localStorage.setItem("RepairRequisition.NguoiDeNghi", this.state.after.NguoiDeNghi);
        localStorage.setItem("RepairRequisition.TruongPhong", this.state.after.TruongPhong);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          if (atts.length > 0) {
            try {
              let dinhKem = Array.isArray(this.state.after.DinhKem) ? this.state.after.DinhKem : JSON.parse(this.state.after.DinhKem);
              atts = atts.concat(dinhKem.map(f => { return { FileID: f }; }));
            } catch (ex) {
              console.error(ex);
            }

            this.state.after.Attachs = atts;
          }

          server
            .post("ChiPhi/DoRequest", {
              Function: "Proc_CP_PhieuGV_Update",
              ThamSo: {
                ...this.state.after
              },
            })
            .then(() => {
              this.props.close(true);
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  getFileID() {
    try {
      let dinhKem = JSON.parse(this.state.after.DinhKem);
      return Array.isArray(dinhKem) && dinhKem.length > 0 ? dinhKem[0] : null;
    } catch (ex) {
      console.warn(ex);
    }

    return null;
  }

  getFileName() {
    return Array.isArray(this.state.after.Attachs) && this.state.after.Attachs.length > 0 ? this.state.after.Attachs[0].FileName : null;
  }

  openEditHMSC() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: this.state.after.HangMucID
        },
      })
      .then((response) => {
        let data = {
          edittingHMSCRecord: {
            ...response.CP_HangMucSC[0],
            NoiDung: response.CP_HangMucSC_ND.map(item => {
              return {
                ...item,
                MaYeuCau: item.MaYeuCau ?? item.MaYeuCauSC,
                MaNoiDung: item.MaNoiDung ?? item.MaNoiDungYC
              }
            })
          },
          open_edit_HMSC: true
        };
        this.setState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}{this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (<></>)}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "SoPhieuGV",
                    })
                  }
                >
                  {fieldLabels.SoPhieuGV}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SoPhieuGV}
                  onChange={(event) => {
                    this.saveField("SoPhieuGV", event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NgayGV",
                    })
                  }
                >
                  {fieldLabels.NgayGV}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateEditor
                  fullWidth
                  value={this.state.after.NgayGV}
                  onChange={(value) => {
                    this.saveField("NgayGV", value, () => {
                      this.getPGVNextSTT();
                    });
                  }}
                  error={this.state.validateStatus.NgayGV !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>
                  {fieldLabels.SoHoSo}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.SoHoSo}
                  onChange={(event) => {
                    this.saveField("SoHoSo", event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaCongTy",
                    })
                  }
                >
                  {fieldLabels.MaCongTy}
                </FormLabel>
              </Grid>
              <Grid item xs={22}>
                <MySelectFilter
                  fullWidth
                  options={this.state.DanhMuc.DM_CongTy}
                  optionValue="MaCongTy"
                  optionLabel="TenCongTy"
                  placeholder="Select contractor"
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "GiamDoc",
                    })
                  }
                >
                  {fieldLabels.GiamDoc}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  placeholder=""
                  value={this.state.after.GiamDoc}
                  onChange={(event) => {
                    this.saveField("GiamDoc", event.target.value);
                  }}
                  error={this.state.validateStatus.GiamDoc !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "PhongYeuCau",
                    })
                  }
                >
                  {fieldLabels.PhongYeuCau}
                </FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  fullWidth
                  placeholder=""
                  value={this.state.after.PhongYeuCau}
                  onChange={(event) => {
                    this.saveField("PhongYeuCau", event.target.value);
                  }}
                  error={this.state.validateStatus.PhongYeuCau !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "YeuCau",
                    })
                  }
                >
                  {fieldLabels.YeuCau}
                </FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  fullWidth
                  placeholder=""
                  value={this.state.after.YeuCau}
                  onChange={(event) => {
                    this.saveField("YeuCau", event.target.value);
                  }}
                  error={this.state.validateStatus.YeuCau !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "HangMucID",
                    })
                  }
                >
                  {fieldLabels.HangMucID}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MySelectFilter
                  fullWidth
                  options={this.state.DanhMuc.DM_HMSC}
                  optionValue="HangMucID"
                  optionLabel="MaHangMuc"
                  placeholder="Select repair item"
                  value={this.state.after}
                  onChange={(item) => {
                    this.saveData(item, () => {
                      this.getPGVNextSTT();
                    });
                  }}
                  error={this.state.validateStatus.HangMucID !== undefined}
                />
              </Grid>
              {this.state.after.HangMucID && <Grid item xs={4}>
                <Button
                  variant="contained"
                  sx={{ marginLeft: "10px" }}
                  onClick={() => {
                    this.openEditHMSC();
                  }}
                >
                  {buttonLabels.btnEdit}
                </Button>
              </Grid>}
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MoTa",
                    })
                  }
                >
                  {fieldLabels.MoTa}
                </FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  fullWidth
                  multiline
                  minRows={3}
                  value={this.state.after.MoTa}
                  onChange={(event) => {
                    this.saveField("MoTa", event.target.value);
                  }}
                  error={this.state.validateStatus.MoTa !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "GhiChu",
                    })
                  }
                >
                  {fieldLabels.GhiChu}
                </FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  fullWidth
                  multiline
                  minRows={3}
                  value={this.state.after.GhiChu}
                  onChange={(event) => {
                    this.saveField("GhiChu", event.target.value);
                  }}
                  error={this.state.validateStatus.GhiChu !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Attachs}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  fullWidth
                  value={this.getAttachs() || this.getFileName()}
                  placeholder="Purchase order"
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxSize={2 * 1024 * 1024}
                  multiple={false}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={this.getFileID() == null}
                  href={server.getRequest(`ChiPhi/GetFile?fileID=${this.getFileID()}`)}
                  target="_blank"
                >
                  {buttonLabels.btnDownload}
                </Button>
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NguoiDeNghi",
                    })
                  }
                >
                  {fieldLabels.NguoiDeNghi}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="NguoiDeNghi"
                  key="NguoiDeNghi"
                  fullWidth
                  value={this.state.after.NguoiDeNghi}
                  onChange={(event) => {
                    this.saveField("NguoiDeNghi", event.target.value);
                  }}
                  error={this.state.validateStatus.NguoiDeNghi !== undefined}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "TruongPhong",
                    })
                  }
                >
                  {fieldLabels.TruongPhong}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="TruongPhong"
                  key="TruongPhong"
                  fullWidth
                  value={this.state.after.TruongPhong}
                  onChange={(event) => {
                    this.saveField("TruongPhong", event.target.value);
                  }}
                  error={this.state.validateStatus.TruongPhong !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />

        {this.state.open_edit_HMSC ? (
          <RepairSpecificationEditDialog
            open={true}
            edittingRecord={this.state.edittingHMSCRecord}
            ShipData={this.props.ShipData}
            close={() => {
              this.setState({ open_edit_HMSC: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(POEditDialog);
